import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Tags from '../components/tags'
import Image from "gatsby-image";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Learn web development" keywords={['programming', 'javascript', 'es6']} />
        <div className="post__header">
          <h1>Latest Posts</h1>
        </div>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div className="post-listing" key={node.fields.slug}>
              <small>{node.fields.date}</small>
              <h2>
                <Link style={{ boxShadow: 'none' }} to={`/posts/${node.fields.slug}`}>
                  {title}
                </Link>
              </h2>
              {
                false && node.frontmatter.banner &&
                <Image className="post-listing__image" fixed={node.frontmatter.banner.childImageSharp.fixed} alt=""/>
              }
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              <Link to={`/posts/${node.fields.slug}`}>Continue reading →</Link>
            </div>
          )
        })}
        {/*<Tags/>*/}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark( 
      sort: { fields: [fields___date], order: DESC }
      filter: { fields: { contentType: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
            contentType
          }
          frontmatter {
            title
            banner {
              childImageSharp {
                fixed(width: 240, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            } 
          }
        }
      }
    }
  }
`
